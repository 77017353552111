/* Instructions */
.body {
  background-color: white;
}
.instr-title {
  font-size: 30pt;
  font-weight: bold;
  text-align: center;
}

.attr-td {
  text-align: left;
  font-size: 1.3em;
}

/* INdex */
 .section-sm {
    margin-top: 20px;
    margin-bottom: 20px;
    min-height: 500px;
    padding-bottom: 2rem;
 } 

/* .section-sm-content {
  max-height: 500px;
  overflow: scroll;
} */

/* .footer {
  position: absolute;
  bottom: 0;
  height:50px;
} */

.fade-loader {
    display: block;
    margin: 0 auto;
    border-color: red;
    position: fixed;
    top: 35%;
    left: 48%;
}

.sync-loader {
    display: block;
    margin: 0 auto;
    border-color: red;
    position: fixed;
    top: 5%;
    right: 5%;
}

.pagination {
    text-align: end;
    margin-right: 5em
}

.instr {
    margin-bottom: 25px;
  }

/* FONTS */
.instr-h1 {
    font-size: 2.3em;
    text-align: justify;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    margin-left: 0;
    margin-right: 0;
}

.instr-h2 {
    font-size: 1.8em;
    text-align: justify;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    margin-left: 0;
    margin-right: 0;
}

.instr-h3 {
    font-size: 1.37em;
    text-align: justify;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
}

.instr-h4 {
    font-size: 1.3em;
    text-align: justify;
    margin-top: 1.33em;
    margin-bottom: 1.33em;
    margin-left: 0;
    margin-right: 0;
}

.instr-h5 {
    font-size: 1.13em;
    text-align: justify;
    margin-top: 1.67em;
    margin-bottom: 1.67em;
    margin-left: 0;
    margin-right: 0;
}

.instr-h6 {
    font-size: .97em;
    text-align: justify;
    margin-top: 2.33em;
    margin-bottom: 2.33em;
    margin-left: 0;
    margin-right: 0;
}

/* USERFORM */
form {
    max-width: 500px;
    margin: 0 auto;
}

/* VIsual pattern */
.pressSpace {
    position: fixed;
    top: 40%;
    left: 45%;
    text-align: center;
    -webkit-transform: translate(-40%, -40%);
            transform: translate(-40%, -40%)
}

/* BARGAIN */
.scroll-menu {
    border-style: solid;
    border-color: grey;
    width: 1020px; 
    margin-left: auto;
    margin-right: auto;
    /* padding: 5px; */
}

.scroll-menu-arrow {
  padding: 5px;
  cursor: pointer;
}

.menu-item {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  border: 1px transparent solid;
}

.menu-item-wrapper {
  border: 1px transparent solid;
}

.menu-item-wrapper.active {
  border: 1px blue solid;
}

.menu-item.active {
  border: 1px green solid;
}

.strikethrough {
  position:  relative;
  padding: 2px;
  width:-webkit-fit-content;
  width:-moz-fit-content;
  width:fit-content; 
  height:-webkit-fit-content; 
  height:-moz-fit-content; 
  height:fit-content;
  /* font-family: sans-serif; */
}

.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 40%;
  right: 0;
  border-top: 3px solid ;
  border-color: red;
}

.product-image {
    height: 120px;
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 20px; 
    margin-bottom: 20px; 
    max-height: 120px;
    max-width: 110px;
}

.product-card {
    width: 185px;
    height: 275px;
    padding: 10px;
    margin: 3px;
}

.product-card.active {
  background-color: grey;
}

.arrow-next {
	width: 0;
	height: 0;
	border-top: 30px solid transparent;
	border-left: 30px solid #555;
	border-bottom: 30px solid transparent;
}

.store-btn {
    margin-top: 5px;
    margin-bottom: 5px;
}

.line{
    width: 112px;
    border-bottom: 10px solid black;
    position: absolute;
}

.square {
  height: 130px;
  width: 70px;
  /* opacity: 0.5;   */
  background-color: #e9ecef;
}

.square-left{
  float:left;
  margin-left: -67px;
}
.square-right{
  float:right;
  margin-right: -67px;
}

.stack-top{
    z-index: 1;
}
.stack-bottom{
    z-index: -1;
    position:relative;
}

/* BARGAIN DEMO INSTRUCTION */
.text-highlight{
  color: red;
}
.helper {
  --reactour-accent: #5cb7b7;
  line-height: 1.3;
  color: #000000;
}

.mask {
  color: #021d1d;
  opacity: 0.1;
  /* display: none */
}

.mask-hidden {
  display: none
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.top-quarter {
  margin-top: 13%
}

.modal-alert{
  position: fixed;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  top: -3%;
  left: 41%;
  min-width: 300px;
}

/* .modal-header .close {
  display:none;
} */

/* this remove the blue border in product select */
.menu-item-wrapper.active {
  border: 0px blue solid;
}

.cursor-normal-text:hover{
  cursor: default;
}

.footer{
    background-color: rgb(14, 175, 53);
    color: #000000;
    font-size: 1.5em;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.footer-back{
    background-color: rgb(175, 14, 14);
    color: #ffffff;
    font-size: 1.5em;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
