.footer{
    background-color: rgb(14, 175, 53);
    color: #000000;
    font-size: 1.5em;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.footer-back{
    background-color: rgb(175, 14, 14);
    color: #ffffff;
    font-size: 1.5em;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}